@import '../../unifonic-spa-common/src/styles/partials.scss';


$fa-font-path: '../webfonts';

@import './../../unifonic-spa-common/src/assets/vendors/fortawesome/fontawesome-pro/scss/fontawesome';
@import './../../unifonic-spa-common/src/assets/vendors/fortawesome/fontawesome-pro/scss/solid';
@import './../../unifonic-spa-common/src/assets/vendors/fortawesome/fontawesome-pro/scss/regular';
@import './../../unifonic-spa-common/src/assets/vendors/fortawesome/fontawesome-pro/scss/brands';
@import './../../unifonic-spa-common/src/assets/vendors/fortawesome/fontawesome-pro/scss/light';
@import './../../unifonic-spa-common/src/assets/vendors/fortawesome/fontawesome-pro/scss/custom-icons';


.uni-form-container--campaign-form {
    max-width: 100% !important;

    @include min-screen($desktop) {
        max-width: 80% !important;
    }

    @include min-screen($desktop-big) {
        max-width: 700px !important;
    }
}



.container{
    width: 100%;
    padding: 0 15px;
}

.row{
    position: relative;
    margin: 0 -15px 0;
    display: flex;
    flex-wrap: wrap;
}

@for $i from 1 through 12 {

    .col-#{$i}{
        width: calc(100% / #{12 / $i});
        padding: 15px;
        box-sizing: border-box;
    }

    @include max-screen($tablet-big) {
        .col-sm-#{$i}{
            width: calc(100% / #{12 / $i});
            padding: 15px;
            box-sizing: border-box;
        }

    }
}


.mat-select-panel{
    max-height: 400px !important;
}


uni-view{
    position: relative;
    z-index: 0;
}

.cdk-overlay-container{
    z-index: 1;
}


.mat-tooltip{
    line-height: 1.6em;
    font-size: 11px;
    margin: 5px !important;
}
.mat-tooltip-trigger{
    color: inherit !important;
}

// hack to simplify centerizing of cdk popups
.popup-force-center{
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    bottom: initial !important;
    right: initial !important;
}

uni-box.flat-box {
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0 !important;
}
